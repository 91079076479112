import React from 'react';
import NewScV from './components/NewScV';
import './App.css';
import './utils/reset.css';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Обновляем состояние, чтобы отобразить запасной UI
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // Можно отправить лог ошибки на сервер
        console.error("Error caught in Error Boundary:", error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return <h1>Что-то пошло не так. Попробуйте обновить страницу.</h1>;
        }
        return this.props.children;
    }
}

function App() {
    return (
        <div className='App'>
            <ErrorBoundary>
                <NewScV/>
            </ErrorBoundary>
        </div>
    );
}

export default App;
