export const findDefM = (data) => {
    if (data) {

        //step1: parent
        //check if parent contain defM
        const parent = data.find((item) => item.defM)
        if (parent) return parent

        //step2: sub
        const parents = data.filter((sub) => sub.children)
        let subs
        let sub

        if (parents?.length > 0) {
            subs = [].concat(...parents.map((sub) => sub.children))
            subs = subs.filter(
                (sub) => (sub.children && sub.children.length > 0) || sub.defM
            )
            sub = subs.find((sub) => sub.defM)
        }

        if (sub) return sub

        //step3: child
        let children
        let child

        if (subs?.length > 0) {
            children = [].concat(...subs.map((child) => child.children))
            child = children.find((child) => child.defM)
        }

        if (child) return child

        //step4: subchild
        let subchildren
        let subchild
        if (children?.length > 0) {
            subchildren = []
                .concat(...children.map((child) => child.children))
                .filter((child) => child)
            subchild = subchildren.find((child) => child.defM)
        }

        if (subchild) return subchild
        return false
    }
    return false
}
export const ModuleList = (item) => {
    let array = []
    let isModule = (t) => {
        return t.map(i => i.children ? i.children.map(it => it.children ? it.children : it) : i)
    }
    let isOut = (itemLink) => {
        if (itemLink.linkType && itemLink.linkType[0] !== "outLink") return [itemLink.title, itemLink.module]
    }
    isModule(item).map(i => i.title ? isOut(i) && array.push(isOut(i)) :
        isModule(i).map(it => it.title ? isOut(it) && array.push(isOut(it)) :
            isModule(it).map(its => its.title ? isOut(its) && array.push(isOut(its)) :
                isModule(its).map(itsr => isOut(itsr) && array.push(isOut(itsr))))))
    return array
}
export const titleHeader = (data, names) => {
    const directories = data
    let ob = null
    directories.forEach((sub) => {
        if (sub?.title === names) ob = [sub.title]
        else if (sub?.children) {
            sub.children.forEach((child) => {
                if (child?.title === names) ob = [sub.title, child.title]
                else if (child?.children)
                    child.children.forEach((childs) => {
                        if (childs?.title === names) ob = [sub.title, child.title, childs.title]
                        else if (childs?.children)
                            ob = childs.children.find((subChild) => subChild.title)
                    })
            })
        }
    })
    if (ob) return ob
}
export const LinksType = (data, names) => {
    const directories = data
    let ob = null
    directories.forEach((sub) => {
        if (sub?.title === names) ob = sub
        else if (sub?.children) {
            sub.children.forEach((child) => {
                if (child?.title === names) ob = child
                else if (child?.children)
                    child.children.forEach((childs) => {
                        if (childs?.title === names) ob = childs
                        else if (childs?.children)
                            ob = childs.children.find((subChild) => subChild)
                    })
            })
        }
    })
    if (ob) return ob
}
