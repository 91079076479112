// NavBar.js

import React, { useEffect, useState } from 'react';
import './navigation-bar.css';
import { Button, Menu } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { ReactComponent as LogoSC } from '../../assets/img/logo/logo.svg';
import DOMPurify from 'dompurify';

const NavBar = ({
                    setClickMenu,
                    dataNav,
                    iconData,
                    collapse,
                    setCollapse,
                    subItem,
                    setSubItem,
                    setLinkType,
                    clickMenu,
                }) => {
    const { SubMenu } = Menu;
    const [isClickMenu, setIsClickMenu] = useState(null);

    const handleClick = (e) => setSubItem(e.keyPath);

    // Получение иконки для пункта меню
    const iconF = (item) => {
        const [iconItem, theme] = iconRec(iconData, item.iconName);
        return iconItem ? (
            <div className={`icon_navMenu ${theme}-theme`}>
                {iconItem.SVG}
            </div>
        ) : null;
    };

    const iconRec = (item, ic) => {
        const matchedItem = item.filter((i) => i.name.includes(ic))[0];
        return matchedItem ? [matchedItem, 'dark'] : [null, 'dark'];
    };

    // Обработка выбора модуля
    const moduleSelected = (item) => {
        setClickMenu([item.title, item.module]);
        setLinkType(item.linkType);
    };

    const isChild = (item) => item.children && item.children.length > 0; // Проверка, есть ли дочерние элементы

    // Санация текста перед использованием
    const sanitizedTitle = (title) => DOMPurify.sanitize(title);

    const MenuItems = (item) => {
        const safeTitle = sanitizedTitle(item.title);

        if (item.linkType[0] === 'outLink') {
            return (
                <li className="ant-menu-item double_ant" key={safeTitle}>
                    <div
                        className={`icon_navMenu ${iconRec(iconData, item.iconName)[1]}-theme noCopy`}
                    >
                        {iconRec(iconData, item.iconName)[0]?.SVG}
                    </div>
                    <a
                        href={item.module}
                        target="_blank"
                        rel="noreferrer noopener"
                        style={{ marginLeft: '10px' }}
                    >
                        {safeTitle}
                    </a>
                </li>
            );
        } else {
            return (
                <Menu.Item
                    key={safeTitle}
                    icon={
                        <div
                            className={`icon_navMenu ${iconRec(iconData, item.iconName)[1]}-theme noCopy`}
                        >
                            {iconRec(iconData, item.iconName)[0]?.SVG}
                        </div>
                    }
                    onClick={() => {
                        if (checkUrl(item)[0] && item.linkType[0] !== 'outLink') {
                            moduleSelected(item);
                        }
                    }}
                >
                    {safeTitle}
                </Menu.Item>
            );
        }
    };

    const AntMenuCreated = (items) => {
        const subClass = `navigation_bar noCopy`;
        const popupClass = `NavBar_item ${collapse ? 'collapse' : 'noCollapse'}`;

        return items.map((i) =>
            i.isDirectory ? (
                <SubMenu
                    key={sanitizedTitle(i.title)}
                    title={sanitizedTitle(i.title)}
                    icon={iconF(i)}
                    className={subClass}
                    popupClassName={popupClass}
                >
                    {isChild(i) &&
                        i.children.map((it) =>
                            it.isDirectory ? (
                                <SubMenu
                                    key={sanitizedTitle(it.title)}
                                    title={sanitizedTitle(it.title)}
                                    icon={iconF(it)}
                                    className={subClass}
                                    popupClassName={popupClass}
                                >
                                    {isChild(it) &&
                                        it.children.map((its) =>
                                            its.isDirectory ? (
                                                <SubMenu
                                                    key={sanitizedTitle(its.title)}
                                                    title={sanitizedTitle(its.title)}
                                                    icon={iconF(its)}
                                                    className={subClass}
                                                    popupClassName={popupClass}
                                                >
                                                    {isChild(its) &&
                                                        its.children.map((itsr) =>
                                                            itsr.isDirectory ? (
                                                                <SubMenu
                                                                    key={sanitizedTitle(itsr.title)}
                                                                    title={sanitizedTitle(itsr.title)}
                                                                    icon={iconF(itsr)}
                                                                    className={subClass}
                                                                    popupClassName={popupClass}
                                                                />
                                                            ) : (
                                                                MenuItems(itsr)
                                                            )
                                                        )}
                                                </SubMenu>
                                            ) : (
                                                MenuItems(its)
                                            )
                                        )}
                                </SubMenu>
                            ) : (
                                MenuItems(it)
                            )
                        )}
                </SubMenu>
            ) : (
                MenuItems(i)
            )
        );
    };

    useEffect(() => {
        if (clickMenu) {
            setIsClickMenu([clickMenu[0]]);
        }
    }, [clickMenu]);

    return (
        <div
            className={`NavBar_wrapper ${collapse ? 'collapse' : 'noCollapse'} noCopy`}
        >
            {subItem && (
                <div className="NavBar_wrap">
                    <div className="NavMenu_logo">
                        <Button
                            type="primary"
                            onClick={() => setCollapse(!collapse)}
                            className="NavMenu_btn"
                        >
                            {React.createElement(
                                collapse ? MenuUnfoldOutlined : MenuFoldOutlined
                            )}
                        </Button>
                        <div
                            className={`NavMenu_logo_item ${
                                !collapse ? 'active' : 'noActive'
                            }`}
                        >
                            <LogoSC />
                        </div>
                    </div>
                    {isClickMenu && (
                        <Menu
                            onClick={handleClick}
                            inlineCollapsed={collapse}
                            className="NavBar_tree"
                        >
                            {AntMenuCreated(dataNav)}
                        </Menu>
                    )}
                </div>
            )}
            {!collapse && (
                <span className="click_area_nav" onClick={() => setCollapse(true)} />
            )}
        </div>
    );
};

export default NavBar;

const checkUrl = (item) => {
    const safeTitle = DOMPurify.sanitize(item.title);

    if (item.linkType[0] === 'outLink') {
        return [
            false,
            <a href={item.module} target="_blank" rel="noreferrer noopener">
                {safeTitle}
            </a>,
        ];
    } else {
        return [true, safeTitle];
    }
};
